import React, { useEffect, useState } from "react";
import { OuterContainer } from "../style";
import { Input, message, Spin, Tree, Typography, Table, Card, Badge } from "antd";
import { EditOutlined, CheckOutlined, CloseOutlined, BranchesOutlined, TableOutlined } from "@ant-design/icons";
import { getCostCodesApi, updateCostCodeMappingApi } from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";
import { 
  ContentContainer, 
  TableContainer, 
  TreeViewContainer, 
  ViewToggle, 
  LoadingContainer, 
  ActionButtonContainer, 
  ActionButton, 
  TreeNodeContainer, 
  TreeNodeActions,
  PageHeader,
  EditableInput,
  ValueDisplay,
  CodeLabel
} from "./styles";

const { Title, Text } = Typography;

const CostCodes = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingKey, setEditingKey] = useState("");
  const [editingValue, setEditingValue] = useState("");
  const [expandedKeys, setExpandedKeys] = useState([]);

  const fetchCostCodes = async () => {
    setLoading(true);
    try {
      console.log("fetching cost codes");
      const res = await getCostCodesApi();
      
      // Check if response is directly the array of cost codes or if it's wrapped in a data property
      let costCodes = [];
      if (Array.isArray(res)) {
        costCodes = res;
      } else if (res && Array.isArray(res.data)) {
        costCodes = res.data;
      } else {
        costCodes = [];
      }
      
      // Ensure each item has a unique key for React
      const processedCodes = costCodes.map(code => ({
        ...code,
        key: code.id, // Prefix with namespace to avoid collisions
      }));
      
      setData(processedCodes);
      
      // Set initial expanded keys to top-level codes
      const topLevelKeys = processedCodes
        .filter(item => !item.parent_id)
        .map(item => item.key);
      setExpandedKeys(topLevelKeys);
    
    } catch (error) {
      errorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCostCodes();
  }, []);

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    setEditingKey(record.id);
    setEditingValue(record.value?.toString() || "0");
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      // Validate the input is a number
      const numValue = parseFloat(editingValue);
      if (isNaN(numValue)) {
        message.error("Please enter a valid number");
        return;
      }

      const res = await updateCostCodeMappingApi(record.id, numValue);
      console.log("res", res);
      // If the response is empty or undefined, assume success as the server might not return any data
      if (Object.keys(res).length !== 0 || res?.status === 200) {
        // Instead of refreshing all data, just update this record in the state
        console.log("updating data");
        const updatedData = data.map(item => {
          if (item.id === record.id) {
            return { ...item, value: numValue };
          }
          return item;
        });
        
        setData(updatedData);
        message.success("Cost code mapping updated successfully");
        setEditingKey("");
      } else {
        errorMessage(res);
      }
    } catch (error) {
      errorMessage(error);
    }
  };

  // Build a tree structure for cost codes
  const buildTree = (codes) => {
    // Create a lookup map by ID
    const map = {};
    codes.forEach(code => {
      map[code.id] = { 
        ...code, 
        children: [],
        // Ensure unique key property for React rendering
        key: code.key || `costcode-${code.id}`
      };
    });

    // Build the tree
    const tree = [];
    codes.forEach(code => {
      // Check if this is a child node and the parent exists
      if (code.parent_id && map[code.parent_id]) {
        map[code.parent_id].children.push(map[code.id]);
      } else if (!code.parent_id) {
        // Only add to the root if it doesn't have a parent
        tree.push(map[code.id]);
      } else if (code.parent_id && !map[code.parent_id]) {
        // If parent doesn't exist, add to root
        // This handles orphaned nodes
        tree.push(map[code.id]);
      }
    });

    return tree;
  };

  const columns = [
    {
      title: "Cost Code",
      dataIndex: "full_code",
      key: "code",
      width: "15%",
      sorter: (a, b) => a.full_code.localeCompare(b.full_code),
      render: (text) => <CodeLabel>{text}</CodeLabel>,
    },
    {
      title: "Description",
      dataIndex: "name",
      key: "name",
      width: "40%",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Mapping Value",
      dataIndex: "value",
      key: "value",
      width: "25%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <EditableInput
            value={editingValue}
            onChange={(e) => setEditingValue(e.target.value)}
            onPressEnter={() => save(record)}
          />
        ) : (
          <ValueDisplay $highlighted={record.value !== 0}>
            {record.value !== undefined ? record.value : 0}
          </ValueDisplay>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "operation",
      key: "operation", // Ensure unique key for column
      width: "20%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <ActionButtonContainer>
            <ActionButton onClick={() => save(record)}>
              <CheckOutlined />
            </ActionButton>
            <ActionButton onClick={cancel}>
              <CloseOutlined />
            </ActionButton>
          </ActionButtonContainer>
        ) : (
          <ActionButton onClick={() => edit(record)} disabled={editingKey !== ""}>
            <EditOutlined />
          </ActionButton>
        );
      },
    },
  ];

  // When a node is expanded or collapsed in the tree
  const onExpandTree = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
  };

  // Tree rendering function for a hierarchical view
  const renderTree = () => {
    // Build the tree structure from the current data state
    const treeData = buildTree(data);
    
    const renderTreeNode = (node, index) => {
      return {
        title: (
          <TreeNodeContainer key={`title-${node.key}`}>
            <div>
              <CodeLabel>{node.full_code}</CodeLabel> {node.name}
            </div>
            <TreeNodeActions>
              <div>
                Value: <ValueDisplay $highlighted={node.value !== 0}>{node.value !== undefined ? node.value : 0}</ValueDisplay>
              </div>
              {editingKey === node.id ? (
                <ActionButtonContainer>
                  <EditableInput
                    value={editingValue}
                    onChange={(e) => setEditingValue(e.target.value)}
                    onPressEnter={() => save(node)}
                  />
                  <ActionButton onClick={() => save(node)}>
                    <CheckOutlined />
                  </ActionButton>
                  <ActionButton onClick={cancel}>
                    <CloseOutlined />
                  </ActionButton>
                </ActionButtonContainer>
              ) : (
                <ActionButton onClick={() => edit(node)} disabled={editingKey !== ""}>
                  <EditOutlined />
                </ActionButton>
              )}
            </TreeNodeActions>
          </TreeNodeContainer>
        ),
        key: node.key,
        children: node.children.length > 0 ? node.children.map((child, idx) => renderTreeNode(child, idx)) : []
      };
    };
    
    return (
      <Tree
        showLine={{ showLeafIcon: false }}
        expandedKeys={expandedKeys}
        onExpand={onExpandTree}
        treeData={treeData.map((node, index) => renderTreeNode(node, index))}
      />
    );
  };

  // Handle page change in the table
  const handleTablePageChange = (page, pageSize) => {
    // We maintain state but don't need to fetch new data
    // This ensures the scroll position isn't reset
    console.log(`Page changed to ${page}, pageSize: ${pageSize}`);
  };

  return (
    <OuterContainer>
      <PageHeader>
        <Title level={2}>Standard Cost Codes Mappings</Title>
        <Text type="secondary">
          Manage the mapping values for standard cost codes. You can update values by clicking the edit icon.
        </Text>
      </PageHeader>
      
      {loading ? (
        <LoadingContainer>
          <Spin size="large" />
        </LoadingContainer>
      ) : (
        <ContentContainer>
          <ViewToggle>
            <BranchesOutlined style={{ fontSize: '20px', marginRight: '12px', color: '#1890ff' }}/>
            <Title level={4}>Hierarchical View</Title>
          </ViewToggle>
          <TreeViewContainer>
            {renderTree()}
          </TreeViewContainer>
          
          <ViewToggle>
            <TableOutlined style={{ fontSize: '20px', marginRight: '12px', color: '#1890ff' }}/>
            <Title level={4}>Table View</Title>
          </ViewToggle>
          <TableContainer>
            <Table
              columns={columns}
              dataSource={data}
              rowKey="key"
              pagination={{
                position: ["bottomCenter"],
                pageSize: 50,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100'],
                onChange: handleTablePageChange
              }}
              scroll={{ x: true }}
              loading={loading}
            />
          </TableContainer>
        </ContentContainer>
      )}
    </OuterContainer>
  );
};

export default CostCodes; 