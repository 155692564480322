import React, { useCallback, useEffect, useState } from "react";
import { Spin, Input, Table, message, Select, Tooltip, Space, Button } from "antd";
import { SearchOutlined, InfoCircleOutlined, EditOutlined, CheckOutlined, CloseOutlined, LinkOutlined } from "@ant-design/icons";
import { 
  getProcoreUsersApi, 
  getCostCodesCompactApi, 
  updateUserCostCodeApi
} from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";
import CustomePagination from "../../components/table/pagination";
import { getProcoreUserUrl } from "../../config/environment";
import { 
  ProcoreUsersWrapper, 
  ActionButton, 
  EditableSelect, 
  LoadingContainer,
  SearchContainer,
  PageSizeSelector,
  ExternalLinkButton
} from "./styles";

const { Search } = Input;
const { Option } = Select;

const ProcoreUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [costCodes, setCostCodes] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [editingCostCode, setEditingCostCode] = useState("");
  const [pageSize, setPageSize] = useState(10);
  
  // Fetch Procore users
  const fetchProcoreUsers = async () => {
    setLoading(true);
    try {
      const response = await getProcoreUsersApi(currentPage, pageSize, searchText);
      
        // Handle different possible response structures
        if (response.data) {
          // Response with nested data structure: { data: { data: [...], meta: {...} } }
          setUsers(response.data || []);
          setTotalUsers(response.meta?.total || 0);
        }
    
    } catch (error) {
      errorMessage(error);
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch cost codes for dropdown
  const fetchCostCodes = async () => {
    try {
      const response = await getCostCodesCompactApi();
      setCostCodes(response);
    } catch (error) {
      errorMessage(error);
    }
  };
  
  useEffect(() => {
    fetchProcoreUsers();
  }, [currentPage, searchText, pageSize]);
  
  useEffect(() => {
    fetchCostCodes();
  }, []);
  
  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(1);
  };
  
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page when changing page size
  };
  
  // Cost code edit functions
  const isEditing = (record) => record.id === editingKey;
  
  const edit = (record) => {
    setEditingKey(record.id);
    setEditingCostCode(record.internal_cost_code_id || null);
  };
  
  const cancel = () => {
    setEditingKey("");
  };
  
  const save = async (record) => {
    try {
      // If editingCostCode is null or undefined (from the allowClear option),
      // we're removing the mapping, so send -1 as the value
      const costCodeToSend = editingCostCode === null || editingCostCode === undefined ? -1 : editingCostCode;
      const response = await updateUserCostCodeApi(record.id, costCodeToSend);
      
      if (response?.message || response?.status === 200) {
        // Update the local state
        const newUsers = [...users];
        const index = newUsers.findIndex(item => item.id === record.id);
        if (index > -1) {
          const item = newUsers[index];
          // In the UI we still want to show null for removed mappings
          newUsers.splice(index, 1, { 
            ...item, 
            internal_cost_code_id: costCodeToSend === -1 ? null : costCodeToSend 
          });
          setUsers(newUsers);
        }
        
        // Show appropriate success message
        if (costCodeToSend === -1) {
          message.success("Cost code mapping removed successfully");
        } else {
          message.success("Cost code updated successfully");
        }
      } else {
        errorMessage(response);
      }
    } catch (error) {
      errorMessage(error);
    } finally {
      setEditingKey("");
    }
  };
  
  // Find cost code name by ID
  const getCostCodeName = (costCodeId) => {
    if (!costCodeId) return "Not set";
    const costCode = costCodes.find(code => code.id === costCodeId);
    return costCode ? costCode.full_code : "Unknown";
  };
  
  // Handle navigating to Procore
  const navigateToProcore = (userId) => {
    if (userId) {
      const url = getProcoreUserUrl(userId);
      console.log(`Opening Procore URL: ${url}`);
      window.open(url, '_blank');
    }
  };
  
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email_address",
      key: "email_address",
      sorter: (a, b) => a.email_address.localeCompare(b.email_address),
    },
    {
      title: "Phone",
      dataIndex: "mobile_phone",
      key: "mobile_phone",
    },
    {
      title: "Business ID",
      dataIndex: "business_id",
      key: "business_id",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Internal Cost Code",
      dataIndex: "internal_cost_code_id",
      key: "internal_cost_code_id",
      render: (_, record) => {
        const editable = isEditing(record);
        const isEditingAnotherRow = editingKey !== "" && editingKey !== record.id;
        
        return editable ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <EditableSelect
              value={editingCostCode}
              onChange={(value) => setEditingCostCode(value)}
              options={costCodes.map(code => ({
                value: code.id,
                label: `${code.full_code} - ${code.name}`,
              }))}
              allowClear
              placeholder="Select a cost code"
              style={{ flex: 1 }}
            />
            <div style={{ display: 'flex', marginLeft: '10px' }}>
              <ActionButton onClick={() => save(record)}>
                <CheckOutlined />
              </ActionButton>
              <ActionButton onClick={cancel}>
                <CloseOutlined />
              </ActionButton>
            </div>
          </div>
        ) : (
          <span 
            onClick={() => !isEditingAnotherRow && edit(record)} 
            className={!record.internal_cost_code_id ? 'clickable-cell' : ''}
            style={{ 
              cursor: isEditingAnotherRow ? 'not-allowed' : 'pointer', 
              opacity: isEditingAnotherRow ? 0.5 : 1,
            }}
          >
            {getCostCodeName(record.internal_cost_code_id)}
          </span>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 120,
      render: (_, record) => (
        <Tooltip title={`View user ${record.name} in Procore`}>
          <ExternalLinkButton 
            onClick={(e) => {
              e.stopPropagation();
              navigateToProcore(record.id);
            }}
          >
            <LinkOutlined /> View in Procore
          </ExternalLinkButton>
        </Tooltip>
      ),
    }
  ];
  
  return (
    <ProcoreUsersWrapper>
      <div className="table-header">
        <div className="table-title">Procore Users Management</div>
      </div>
      
      <div className="search-section">
        <SearchContainer>
          <Search
            placeholder="Search users..."
            allowClear
            enterButton={<SearchOutlined />}
            size="large"
            onSearch={handleSearch}
          />
          <div className="search-hint">
            Search by name, email, phone, business ID, job title
            <Tooltip title="You can search by name, email, phone number, business ID, or job title">
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </div>
        </SearchContainer>
        <div className="page-size-container">
          <span>Users per page: </span>
          <PageSizeSelector
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <Option value={10}>10</Option>
            <Option value={25}>25</Option>
            <Option value={50}>50</Option>
          </PageSizeSelector>
        </div>
      </div>
      
      {loading ? (
        <LoadingContainer>
          <Spin size="large" />
        </LoadingContainer>
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={users}
            rowKey="id"
            pagination={false}
          />
          <div className="pagination-container">
            <div className="showing-entries">
              Showing {users.length > 0 ? (currentPage - 1) * pageSize + 1 : 0} to {Math.min(currentPage * pageSize, totalUsers)} of {totalUsers} entries
            </div>
            <CustomePagination
              current={currentPage}
              total={totalUsers}
              onPageChange={onPageChange}
              defaultPageSize={pageSize}
            />
          </div>
        </>
      )}
    </ProcoreUsersWrapper>
  );
};

export default ProcoreUsers; 