import { message } from "antd";

export const errorMessage = (result) =>
  message.error(result?.message || "Something went wrong");

export const activeRoute = () => {
  const currentRoute = window.location.pathname;
  return url_connected_SidebarName[currentRoute];
};

const url_connected_SidebarName = {
  "/members": "Member Management", //"Team Management",
  "/team-members": "Team Management",
  "/vehicle": "Vehicle Management",
  "/project-management": "Project Management",
  "/new-requests": "Project Management",
  "/labour-equipment-management": "Labour & Equipment Management",
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return 'N/A';
  
  // Remove all non-digits
  const cleaned = phoneNumber.replace(/\D/g, '');
  
  // Format as (XXX) XXX-XXXX
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  
  return phoneNumber; // Return original if can't format
};