// Environment Configuration

// Environment Type: 'dev' or 'prod'
export const ENVIRONMENT_TYPE = 'prod';

// Environment Connection Type: 'local' or 'deployed'
export const ENVIRONMENT_CONNECTION_TYPE = 'deployed';

// Derived URLs based on environment configuration
export const getBaseApiUrl = () => {
  if (ENVIRONMENT_TYPE === 'dev') {
    return ENVIRONMENT_CONNECTION_TYPE === 'local' 
      ? 'http://localhost:5678/' 
      : 'https://devapi.restorefastdataendpoint.com/';
  } else {
    return ENVIRONMENT_CONNECTION_TYPE === 'local'
      ? 'http://localhost:5678/' // Local prod (if needed)
      : 'https://www.restorefastdataendpoint.com/';
  }
};

// Procore URLs
export const getProcoreBaseUrl = () => {
  return ENVIRONMENT_TYPE === 'dev' 
    ? 'https://login-sandbox.procore.com' 
    : 'https://login.procore.com';
};

export const getProcoreBaseUrlApi = () => {
  return ENVIRONMENT_TYPE === 'dev' 
    ? 'https://sandbox.procore.com' 
    : 'https://us02.procore.com';
};

// Procore Company ID
export const getProcoreCompanyId = () => {
  return ENVIRONMENT_TYPE === 'dev' ? '4271567' : '562949953425469';
};

// Procore OAuth URLs
export const getProcoreOAuthUrl = () => {
  if (ENVIRONMENT_TYPE === 'dev') {
    const clientId = 'a17012287f80f65588f42d25487cb79b88a67a469691b06cb840c6148b6df7e8';
    const redirectUri = ENVIRONMENT_CONNECTION_TYPE === 'local'
      ? 'http://localhost:3000/'
      : 'https://devadmin.restorefastdataendpoint.com/';
    
    return `${getProcoreBaseUrl()}/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
  } else {
    const clientId = 'e80d0faa29e67fcba9c8078727298309a23bcc0a30aeaf9031e7d40da19169fa';
    const redirectUri = ENVIRONMENT_CONNECTION_TYPE === 'local'
      ? 'http://localhost:3000/'
      : 'https://admin.restorefastdataendpoint.com/';
    
    return `${getProcoreBaseUrl()}/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
  }
};

// Helper function to get Procore project URL
export const getProcoreProjectUrl = (projectId) => {
  return `${getProcoreBaseUrlApi()}/${projectId}/project/home`;
};

// Helper function to get Procore daily log URL
export const getProcoreDailyLogUrl = (projectId, dateString) => {
  const companyId = getProcoreCompanyId();
  console.log(`${getProcoreBaseUrlApi()}/webclients/host/companies/${companyId}/projects/${projectId}/tools/dailylog/list?date=${dateString}`)
  return `${getProcoreBaseUrlApi()}/webclients/host/companies/${companyId}/projects/${projectId}/tools/dailylog/list?date=${dateString}`;
};

// Helper function to get Procore user URL
export const getProcoreUserUrl = (userId) => {
  const companyId = getProcoreCompanyId();
  const baseUrl = getProcoreBaseUrlApi();
  return `${baseUrl}/${companyId}/company/directory/users/${userId}`;
};

// Helper function to get Procore daily log list URL
export const getProcoreDailyLogListUrl = (projectId, dateString) => {
  const companyId = getProcoreCompanyId();
  const baseUrl = getProcoreBaseUrlApi();
  return `${baseUrl}/webclients/host/companies/${companyId}/projects/${projectId}/tools/dailylog/list?date=${dateString}`;
};

// Helper function to get Procore timecard edit URL
export const getProcoreTimecardEditUrl = (projectId, entryId, dateString) => {
  const companyId = getProcoreCompanyId();
  const baseUrl = getProcoreBaseUrlApi();
  return `${baseUrl}/webclients/host/companies/${companyId}/projects/${projectId}/tools/dailylog/timecards/${entryId}/edit?date=${dateString}`;
};

// Helper function to get Procore quantities edit URL
export const getProcoreQuantitiesEditUrl = (projectId, entryId, dateString) => {
  const companyId = getProcoreCompanyId();
  const baseUrl = getProcoreBaseUrlApi();
  return `${baseUrl}/webclients/host/companies/${companyId}/projects/${projectId}/tools/dailylog/quantities/${entryId}/edit?date=${dateString}`;
};

// Helper function to get Procore equipment edit URL
export const getProcoreEquipmentEditUrl = (projectId, entryId, dateString) => {
  const companyId = getProcoreCompanyId();
  const baseUrl = getProcoreBaseUrlApi();
  return `${baseUrl}/webclients/host/companies/${companyId}/projects/${projectId}/tools/dailylog/equipment/${entryId}/edit?date=${dateString}`;
}; 