import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ActionWrapper, OuterContainer } from "../style";
import SearchField from "../../components/fields/searchField";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import { Button, Image, Tooltip, message } from "antd";
import {
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import ModalComponent from "../../components/modal";
import ConfirmModal from "../../components/modal/confirmModal";
import ViewDetails from "./viewInfo";
import DrawerComponent from "../../components/drawer";
import ProjectForm from "./form";
import {
  deleteProjectApi,
  getcompletetask,
  getinprogrsstaskapi,
  getsearchdata,
} from "../../services/Api/collection";
import { errorMessage,formatPhoneNumber } from "../../utils/commonMethod";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getProcoreUserUrl } from "../../config/environment";

const limit = 10;
export const ProjectManagement = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordview, setRecordView] = useState();
  const [record, setRecord] = useState();
  const [opendeatilsmodal, setopendetailsmodal] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleSearch = (searchText) => setSearchText(searchText);

  const onPageChange = (page) => setCurrentPage(page);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const getProjectLists = async () => {
    setLoading(true);
    let project_id;
    if (params.has("project_id")) {
      project_id = params.get("project_id");
    } else {
      project_id = "All";
    }

    const res = await getinprogrsstaskapi(currentPage, limit, project_id);
    console.log(res, "Active Project Data Return");
    if (res?.status === 200) {
      setData(res?.data?.Requests);
      setTotalCount(res?.data?.TotalCount);
    } else errorMessage(res);
    setLoading(false);
  };

  const handleDrawerOpenClose = (e) => {
    setViewModal((pre) => !pre);
    setRecordView(e);
  };

  const openDeleteConfirmModal = (record) => {
    setUserInfo(record);
    setOpenDeleteModal((pre) => !pre);
  };

  const handleSearchData = async () => {
    setLoading(true);
    const isAccept = 2;
    const res = await getsearchdata(currentPage, limit, searchText, isAccept);
    if (res?.status === 200) {
      setTotalCount(res?.data?.TotalCount);
      setData(res?.data?.finalRequestedData);
    } else errorMessage(res);
    setLoading(false);
  };

  useEffect(() => {
    if (searchText) handleSearchData();
    else getProjectLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPage]);

  const closeOpenDeleteModal = () => setOpenDeleteModal((pre) => !pre);

  const closeOpenViewModal = () => setViewModal((pre) => !pre);

  const handleDeleteConfirm = async () => {
    const res = await deleteProjectApi(userInfo?._id);
    if (res?.status === 200) {
      message.success(res?.message);
      currentPage === 1 ? getProjectLists() : setCurrentPage(1);
    } else errorMessage(res);
    setOpenDeleteModal((pre) => !pre);
  };

  const handleAddInfo = (selected_project) => {
    setUserInfo(selected_project);
    setOpenDrawer(true);
  };

  const handleOpenCloseDrawer = () => setOpenDrawer((pre) => !pre);
  const handlecompletetask = async (id) => {
    const req = {
      requestId: id?._id,
    };
    const res = await getcompletetask(req);
    if (res?.status === 200) {
      message.success("Successfully completed project");
      getProjectLists();
    } else {
      errorMessage(res);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "project_name",
      key: "name",
      width: "10%",
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "12%",
      render: (_, record) => {
        const addressParts = [record.address, record.city, record.zip];
        const isEmptyAddress = addressParts.every(part => !part || part === "N/A");
        return (
          <div>
            {isEmptyAddress ? "Empty Address" : addressParts.join(", ")}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "8%",
      render: (_, record) => {
        const statusColors = {
          Completed: "#a0d468",
          InProgress: "#ffce54",
          Rejected: "#ed5565",
          Pending: "#ffcd00",
        };
        const color = statusColors[record.status] || "grey";
        return (
          <div
            style={{
              backgroundColor: color,
              padding: "5px 10px",
              borderRadius: "5px",
              color: "black",
              textAlign: "center",
              fontWeight: "bold",
              minWidth: "80px",
              textTransform: "capitalize",
            }}
          >
            {record.status}
          </div>
        );
      },
    },
    {
      title: "Created By",
      key: "createdBy",
      width: "12%",
      render: (_, record) => {
        if (!record.usersData || record.usersData.length === 0) return 'N/A';
        const user = record.usersData[0];
        return (
          <div style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
            <div style={{ fontWeight: 'bold' }}>{user.name || 'N/A'}</div>
            <div>{user.email_address || 'N/A'}</div>
            <div>{user.mobile_phone || 'N/A'}</div>
          </div>
        );
      },
    },
    {
      title: "Project Manager",
      key: "projectManager",
      width: "12%",
      render: (_, record) => {
        if (!record.projectsMember || record.projectsMember.length === 0) return 'None';
        return (
          <div style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
            {record.projectsMember.map((member, index) => (
              <div key={index} style={{ marginBottom: index !== record.projectsMember.length - 1 ? '8px' : 0 }}>
                <div style={{ fontWeight: 'bold' }}>{member.name || 'N/A'}</div>
                <div>{member.email_address || 'N/A'}</div>
                <div>{formatPhoneNumber(member.mobile_phone) || 'N/A'}</div>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Images",
      key: "images",
      width: "5%",
      render: (_, record) =>
        record.photos && record.photos.length > 0 ? (
          <Button
            icon={<PictureOutlined />}
            onClick={() => {
              setSelectedImages(record.photos);
              setImageModalVisible(true);
            }}
          />
        ) : null,
    },
    {
      title: "Create Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "8%",
      render: (_, data) => (
        <div>{moment(data.start_date).format("MMM D, YYYY")}</div>
      ),
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
    },
    {
      title: "Actions",
      key: "actions",
      width: "40%",
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', alignItems: 'center' }}>
          <Button
            onClick={() => {
              setopendetailsmodal(true);
              setRecord(record);
            }}
          >
            View Details
          </Button>
            <a
            //PROD/DEV/LOCAL SWITCH - Using centralized configuration
            href={getProcoreUserUrl(record.user_id)}
            target="_blank"
            rel="noreferrer"
          >
            <Button>User Details</Button>
          </a>
          <ActionWrapper>
            <Tooltip title="Delete Project">
              <Button
                icon={<DeleteOutlined />}
                onClick={() => openDeleteConfirmModal(record)}
              />
            </Tooltip>
            {record?.status === "InProgress" && (
              <>
                <Tooltip title="View Project Detail">
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() => handleDrawerOpenClose(record)}
                  />
                </Tooltip>
                <Tooltip title="Assign Team/Vehicle">
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => handleAddInfo(record)}
                  />
                </Tooltip>
              </>
            )}
          </ActionWrapper>
          {record?.status === "InProgress" && (
            <Button onClick={() => handlecompletetask(record)}>
              Complete
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <ProjectWrapper>
      <div className="filters">
        <div className="search-wrapper">
          <SearchField handleSearch={handleSearch} />
        </div>
      </div>

      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />

      {/* Image Modal */}
      <ModalComponent
        openModal={imageModalVisible}
        setOpenModal={() => setImageModalVisible(false)}
      >
        <Image.PreviewGroup>
          {selectedImages.map((imageUrl, index) => (
            <Image
              key={index}
              src={`https://de7nc1t6mbc89.cloudfront.net/${imageUrl}`}
              style={{ margin: "10px", maxWidth: "200px" }}
              alt={`Project Image ${index + 1}`}
            />
          ))}
        </Image.PreviewGroup>
      </ModalComponent>

      {openDrawer && (
        <DrawerComponent
          open={openDrawer}
          handleClose={handleOpenCloseDrawer}
          title="Add Team/Vehicle"
        >
          <ProjectForm
            projectInfo={userInfo}
            handleClose={handleOpenCloseDrawer}
            update={getProjectLists}
          />
        </DrawerComponent>
      )}
      {openDeleteModal && (
        <ModalComponent
          openModal={openDeleteModal}
          setOpenModal={closeOpenDeleteModal}
        >
          <ConfirmModal
            handleConfirm={handleDeleteConfirm}
            handleCancel={closeOpenDeleteModal}
          />
        </ModalComponent>
      )}
      {/* Details Modal */}
      <ModalComponent
        openModal={opendeatilsmodal}
        setOpenModal={() => setopendetailsmodal(false)}
      >
        <DetailsModal>
          <h2>{record?.project_name ?? "Project Details"}</h2>
          <DetailsGrid>
            <div className="detail-item">
              <span className="label">Emergency Images:</span>
              <span className="value">
                {record?.photos && record.photos.length > 0 ? (
                  <Image.PreviewGroup>
                    {record.photos.map((e, index) => (
                      <Image
                        key={index}
                        src={`https://de7nc1t6mbc89.cloudfront.net/${e}`}
                        style={{ width: "100px", height: "100px", padding: "5px" }}
                        alt={`Image ${index + 1}`}
                      />
                    ))}
                  </Image.PreviewGroup>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">Address:</span>
              <span className="value">
                {(() => {
                  const addressParts = [
                    record?.address ?? "N/A",
                    record?.city ?? "N/A",
                    record?.zip ?? "N/A",
                  ];
                  const isEmptyAddress = addressParts.every(
                    (part) => !part || part === "N/A"
                  );
                  return isEmptyAddress
                    ? "Empty Address"
                    : addressParts.join(", ");
                })()}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">Status:</span>
              <span className="value">{record?.status ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">Start Date:</span>
              <span className="value">
                {record?.start_date
                  ? moment(record.start_date).format("MMM D, YYYY")
                  : "N/A"}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">Start Time:</span>
              <span className="value">{record?.start_time ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">End Date:</span>
              <span className="value">
                {record?.end_date
                  ? moment(record.end_date).format("MMM D, YYYY")
                  : "N/A"}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">End Time:</span>
              <span className="value">{record?.end_time ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">Comment:</span>
              <span className="value">{record?.comment ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">County:</span>
              <span className="value">{record?.county ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">State Code:</span>
              <span className="value">{record?.state_code ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">Zip:</span>
              <span className="value">{record?.zip ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">Equipment Required:</span>
              <span className="value">
                {record?.equipment?.length
                  ? record?.equipment?.map((e, index) => (
                      <span key={index}>
                        {e?.equipment_name} ({e?.equipment_size})
                        {index < record.equipment.length - 1 ? ", " : ""}
                      </span>
                    ))
                  : "N/A"}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">Labour Required:</span>
              <span className="value">{record?.labor_size ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">Labour Type:</span>
              <span className="value">
                {record?.labor_type === 0
                  ? "General"
                  : record?.labor_type === 1
                  ? "Skilled"
                  : "N/A"}
              </span>
            </div>
          </DetailsGrid>
        </DetailsModal>
      </ModalComponent>
      {viewModal && (
        <ModalComponent
          openModal={viewModal}
          setOpenModal={closeOpenViewModal}
          width={800}
        >
          <ViewDetails vehicle={recordview} />
        </ModalComponent>
      )}
    </ProjectWrapper>
  );
};

const ProjectWrapper = styled(OuterContainer)`
  .filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .new-request {
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    justify-content: end;
    cursor: pointer;
  }
  .request-link {
    display: flex;
    justify-content: right;
  }
  .filters {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
  }
  .search-wrapper {
    flex: 1;
  }
`;

const DetailsModal = styled.div`
  max-width: 800px;
  margin: 0 auto;
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  .detail-item {
    display: flex;
    flex-direction: column;
    .label {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .value {
      margin-left: 10px;
    }
  }
`;


