import styled from "styled-components";
import { OuterContainer } from "../style";
import { Input, Select, Button } from "antd";
import { defaultTheme } from "../../utils/theme";

export const ProcoreUsersWrapper = styled(OuterContainer)`
  .search-section {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search-hint {
    margin-top: 5px;
    color: #999;
    font-size: 12px;
    font-style: italic;
  }
  
  .right-section {
    display: flex;
    gap: 10px;
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .table-title {
    font-size: 20px;
    font-weight: 600;
  }
  
  .page-size-container {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #555;
  }
  
  .clickable-cell {
    cursor: pointer;
    color: ${defaultTheme.primaryColor};
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    
    .showing-entries {
      color: #555;
      font-size: 14px;
    }
  }
`;

export const ExternalLinkButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  height: 32px;
  padding: 0 10px;
  transition: all 0.3s ease;
  
  &:hover {
    color: ${defaultTheme.primaryColor};
    border-color: ${defaultTheme.primaryColor};
    background-color: #e6f7ff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  &:active {
    background: #e6f7ff;
    transform: translateY(1px);
  }
`;

export const PageSizeSelector = styled(Select)`
  width: 70px;
`;

export const CostCodeSelect = styled(Select)`
  min-width: 200px;
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.danger ? 'red' : defaultTheme.primaryColor};
  cursor: pointer;
  padding: 5px;
  margin: 0 5px;
  display: inline-flex;
  align-items: center;
  
  &:hover {
    opacity: 0.8;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const EditableSelect = styled(Select)`
  width: 100%;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
`; 