import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllNotificationUsers, pushNotification } from "../../services/Api/collection";
import { Button, Transfer, Input, Modal, message } from "antd";

const EquipmentWrapper = styled.div`
  padding: 20px;
`;

const ActionButtons = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
`;

const { TextArea } = Input;

export const NotificationCenter = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);

  const getUsers = async () => {
    try {
      const res = await getAllNotificationUsers();
      if (res?.status === 200) {
        const data = res?.data.data;
        console.log("data",data);
        if (Array.isArray(data)) {
          // Map users to the format required by Transfer component
          const formattedUsers = data.map(user => ({
            key: user.user_id,
            title: user.deviceName,
            description: user.email,
          }));
          setUsers(formattedUsers);
          setTargetKeys([]); // Start with no users selected
        } else {
          console.error("Data is not an array:", data);
        }
      } else {
        console.error("Error fetching users:", res);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleTransferChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
    setSelectedUserIds(nextTargetKeys);
  };

  const addAllUsers = () => {
    const allUserKeys = users.map(user => user.key);
    setTargetKeys(allUserKeys);
    setSelectedUserIds(allUserKeys);
  };

  const removeAllUsers = () => {
    setTargetKeys([]);
    setSelectedUserIds([]);
  };

  const handleSubmit = () => {
    if (!title || !body) {
      message.warning('Please enter both title and body for the notification.');
      return;
    }
    if (selectedUserIds.length === 0) {
      message.warning('Please select at least one user to send the notification to.');
      return;
    }
    setConfirmVisible(true);
  };

  const handleConfirm = async () => {
    setConfirmVisible(false);
    setIsSubmitting(true);
    message.info('Notifications are being submitted. Please wait a moment.');
    const notificationData = {
      title,
      body,
      users: selectedUserIds
    };
    try {
      const res = await pushNotification(notificationData);
      if (res?.status === 200) {
        message.success('Notifications have been successfully sent.');
        // Optionally reset the form
        setTitle('');
        setBody('');
        // setTargetKeys([]); // Uncomment if you want to clear the selected users after submission
      } else {
        message.error('Failed to send notifications. Please try again.');
      }
    } catch (error) {
      console.error("Error sending notifications:", error);
      message.error('An error occurred while sending notifications.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setConfirmVisible(false);
  };

  return (
    <EquipmentWrapper>
      <div className="heading-section">
        <h2>Notification Center</h2>
      </div>
      <Input 
        placeholder="Title" 
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      <TextArea 
        placeholder="Body" 
        value={body}
        onChange={(e) => setBody(e.target.value)}
        style={{ marginBottom: '20px' }}
        rows={4}
      />

      <ActionButtons>
        <Button onClick={addAllUsers}>Add All</Button>
        <Button onClick={removeAllUsers}>Remove All</Button>
      </ActionButtons>

      <Transfer
        dataSource={users}
        titles={['Available Users', 'Selected Users']}
        targetKeys={targetKeys}
        onChange={handleTransferChange}
        render={item => item.title}
        showSearch
        listStyle={{
          width: '45%',
          height: 300,
        }}
        style={{ marginBottom: '20px' }}
      />

      <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>
        Submit Notification
      </Button>

      <Modal
        title="Confirm Notification"
        visible={confirmVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to send this notification?</p>
      </Modal>
    </EquipmentWrapper>
  );
};

export default NotificationCenter;
