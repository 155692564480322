import { TeamIcon } from "./svgIcons";
import { NotificationOutlined, FileSearchOutlined, TruckOutlined, DeploymentUnitOutlined, TeamOutlined, VideoCameraOutlined, EnvironmentOutlined, DollarOutlined, UserOutlined} from '@ant-design/icons';

export const sidebarList = [
  {
    name: "Request Management",
    icon: <DeploymentUnitOutlined />,
    link: "/request",
  },
  {
    name: "Standard Cost Codes",
    icon: <DollarOutlined />,
    link: "/cost-codes",
  },
  {
    name: "Procore Users",
    icon: <UserOutlined />,
    link: "/procore-users",
  },
  {
    name: "Invoicing",
    icon: <FileSearchOutlined />,
    link: "/daily-invoice-generator",
  },
  {
    name: "Notification Center",
    icon: <NotificationOutlined />,
    link: "/notification-center"
  },
  {
    name: "Vehicle Management",
    icon: <TruckOutlined />,
    link: "/vehicle",
  },
  {
    name: "Equipment Management",
    icon: <VideoCameraOutlined />,
    link: "/equipment-management",
  },
  {
    name: "Tracking",
    icon: <EnvironmentOutlined />,
    link: "/equipment-tracking",
  },
  {
    name: "Document Management",
    icon: <NotificationOutlined />,
    link: "/document-management"
  }
];
