import React, { useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import { 
  Card, 
  Tabs, 
  Button, 
  Table, 
  Input, 
  Select, 
  Typography, 
  Tag, 
  Space, 
  Tooltip,
  Badge,
  Empty
} from "antd";
import { 
  SearchOutlined, 
  EnvironmentOutlined, 
  TableOutlined, 
  SyncOutlined, 
  FilterOutlined,
  ToolOutlined,
  CarOutlined,
  HomeOutlined,
  BarcodeOutlined,
  HistoryOutlined
} from "@ant-design/icons";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

// This would be replaced with your actual Mapbox token in a real implementation
// mapboxgl.accessToken = 'YOUR_MAPBOX_ACCESS_TOKEN';

const TrackingContainer = styled(OuterContainer)`
  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .view-toggle {
    margin-bottom: 16px;
  }

  .map-container {
    height: 600px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  .filters-section {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;
  }

  .location-tag {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .status-badge {
    margin-right: 8px;
  }

  .table-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .search-input {
    width: 300px;
  }
`;

const EquipmentTracking = () => {
  const [activeView, setActiveView] = useState("map");
  const [searchText, setSearchText] = useState("");
  const [locationFilter, setLocationFilter] = useState("all");
  const [typeFilter, setTypeFilter] = useState("all");
  const [loading, setLoading] = useState(false);

  // Mock data for demonstration
  const equipmentData = [];

  // Table columns configuration
  const columns = [
    {
      title: "Equipment ID",
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <Space>
          <BarcodeOutlined />
          <Text strong>{text}</Text>
        </Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => <Tag color="blue">{type}</Tag>,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location, record) => {
        let icon = <HomeOutlined />;
        let color = "default";

        if (record.locationType === "project") {
          icon = <ToolOutlined />;
          color = "green";
        } else if (record.locationType === "truck") {
          icon = <CarOutlined />;
          color = "orange";
        }

        return (
          <Tag className="location-tag" color={color}>
            {icon} {location}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "success";
        if (status === "In Transit") color = "processing";
        if (status === "Maintenance") color = "warning";
        if (status === "Lost") color = "error";

        return <Badge status={color} text={status} className="status-badge" />;
      },
    },
    {
      title: "Last Scanned",
      dataIndex: "lastScanned",
      key: "lastScanned",
      render: (date, record) => (
        <Tooltip title={`Scanned by ${record.lastScannedBy}`}>
          <Space>
            <HistoryOutlined />
            <span>{date}</span>
          </Space>
        </Tooltip>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: () => (
        <Space size="small">
          <Button type="link" size="small">
            Details
          </Button>
          <Button type="link" size="small">
            History
          </Button>
        </Space>
      ),
    },
  ];

  // Initialize map when component mounts
  // This would be implemented with useEffect in a real application
  const initializeMap = () => {
    // Map initialization code would go here
  };

  const handleRefresh = () => {
    setLoading(true);
    // Simulate data loading
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleViewChange = (key) => {
    setActiveView(key);
  };

  return (
    <TrackingContainer>
      <div className="header-section">
        <Title level={3}>Tracking</Title>
        <Button 
          type="primary" 
          icon={<SyncOutlined />} 
          onClick={handleRefresh}
          loading={loading}
        >
          Refresh Data
        </Button>
      </div>

      <Tabs 
        activeKey={activeView} 
        onChange={handleViewChange}
        className="view-toggle"
        tabBarExtraContent={
          <Space>
            <Button icon={<EnvironmentOutlined />} type={activeView === "map" ? "primary" : "default"}>
              Map View
            </Button>
            <Button icon={<TableOutlined />} type={activeView === "table" ? "primary" : "default"}>
              Table View
            </Button>
          </Space>
        }
      >
        <TabPane tab="Map View" key="map">
          <div className="filters-section">
            <Select 
              placeholder="Filter by location" 
              style={{ width: 200 }} 
              value={locationFilter}
              onChange={setLocationFilter}
            >
              <Option value="all">All Locations</Option>
              <Option value="project">Projects</Option>
              <Option value="warehouse">Warehouse</Option>
              <Option value="truck">Trucks</Option>
            </Select>
            <Select 
              placeholder="Filter by equipment type" 
              style={{ width: 200 }} 
              value={typeFilter}
              onChange={setTypeFilter}
            >
              <Option value="all">All Types</Option>
              <Option value="dehumidifier">Dehumidifiers</Option>
              <Option value="fan">Fans</Option>
              <Option value="airScrubber">Air Scrubbers</Option>
            </Select>
          </div>

          <Card className="map-container">
            <Empty 
              description="Map View Coming Soon" 
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            >
              <Text type="secondary">
                This will display a Mapbox map showing equipment locations across projects, 
                warehouses, and trucks.
              </Text>
            </Empty>
          </Card>

          <Card title="Equipment Summary">
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
              <Card.Grid style={{ width: '25%', textAlign: 'center' }}>
                <Title level={2}>0</Title>
                <Text>Total Equipment</Text>
              </Card.Grid>
              <Card.Grid style={{ width: '25%', textAlign: 'center' }}>
                <Title level={2} style={{ color: '#52c41a' }}>0</Title>
                <Text>At Projects</Text>
              </Card.Grid>
              <Card.Grid style={{ width: '25%', textAlign: 'center' }}>
                <Title level={2} style={{ color: '#1890ff' }}>0</Title>
                <Text>At Warehouse</Text>
              </Card.Grid>
              <Card.Grid style={{ width: '25%', textAlign: 'center' }}>
                <Title level={2} style={{ color: '#faad14' }}>0</Title>
                <Text>In Transit</Text>
              </Card.Grid>
            </div>
          </Card>
        </TabPane>

        <TabPane tab="Table View" key="table">
          <div className="table-actions">
            <Space>
              <Input
                placeholder="Search equipment"
                prefix={<SearchOutlined />}
                className="search-input"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                allowClear
              />
              <Button icon={<FilterOutlined />}>
                Advanced Filters
              </Button>
            </Space>
            <Space>
              <Button type="primary">
                Add Equipment
              </Button>
              <Button>
                Export Data
              </Button>
            </Space>
          </div>

          <Table
            columns={columns}
            dataSource={equipmentData}
            rowKey="id"
            loading={loading}
            pagination={{ pageSize: 10 }}
            locale={{ emptyText: 'No equipment data available' }}
          />
        </TabPane>
      </Tabs>
    </TrackingContainer>
  );
};

export default EquipmentTracking; 