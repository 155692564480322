import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import SearchField from "../../components/fields/searchField";
import ButtonComponent from "../../components/fields/button";
import DrawerComponent from "../../components/drawer";
import Addequipment from "./Addequipment";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import {
  getAllEquipmentlistapi,
  getAllEquipmentsearch,
} from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";

export default function Equipment() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [openDrawer, setOpenDrawer] = useState({ status: false });
  const [loading, setLoading] = useState(true);
  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const limit = 10;

  const columns = [
    {
      title: "Equipment",
      dataIndex: "equipment",
      key: "equipment  ",
    },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (y, data) => (
        <>
          {data?.status ? (
            <>
              {" "}
              <Buttonstatus style={{ background: "red" }}>Inuse</Buttonstatus>
            </>
          ) : (
            <>
              {" "}
              <Buttonstatus>Free</Buttonstatus>
            </>
          )}
        </>
      ),
    },
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
    },
    {
      title: "Address",
      dataIndex: "location.lon",
      key: "location.lon",
      render: (y, data) => (
        <>
          {data?.location?.address.street + " " + data?.location?.address.city + " " + data?.location?.address.state + " " + data?.location?.address.zipcode}
          <br />
          {data?.location?.lon + " " + data?.location?.lat}
        </>
      ),
    },
     {
      title: "Project Details",
      dataIndex: "projectDetails",
      key: "projectDetails",
      render: (y, data) => (
        // console.log(data,"=====")
        <>
          {data?.projectDetails?.ProjectDeatail && data?.status ? (
            <p>{data?.projectDetails?.ProjectDeatail[0]?.project_name}</p>
          ) : (
            <>
              <p></p>
            </>
          )}
        </>
      ),
     },
  ];
  const handleSearch = (searchText) => {
    setCurrentPage(1);
    setSearchText(searchText);
  };

  const handleDrawerOpenClose = (
    type = "add",
    title = "Add Equipment",
    data = {}
  ) => {
    setOpenDrawer({ status: !openDrawer.status, type, title, data });
  };
  const handleSuccess = () => {};
  const handlegetequipmentdata = async () => {
    setLoading(true);
    const res = await getAllEquipmentlistapi(currentPage, limit);
    if (res?.status === 200) {
      console.log(res?.data?.findFreeEquipments, "res");
      setdata(
        res?.data?.findFreeEquipments[0]);
      setTotalCount(res?.data?.findFreeEquipments[1]);
    } else {
      errorMessage(res);
    }
    setLoading(false);
  };
  // useEffect(() => {
  //   handlegetequipmentdata();
  // }, [currentPage]);
  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page);
  }, []);

  const handleSearchData = async (filterPath) => {
    setLoading(true);
    const res = await getAllEquipmentsearch(currentPage, limit, searchText);
    if (res?.status === 200) {
      setdata(
        res?.data?.EquipmentData?.map((e) => ({
          equipment: e?.equipment,
          make: e?.asset_info?.make,
          model: e?.asset_info?.model,
          type: e?.asset_info?.type,
          status: e?.status,
          projectDetails: e,
        }))
      );
      setTotalCount(res?.data?.totalCount);
    } else {
      errorMessage(res);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (searchText) handleSearchData();
    else handlegetequipmentdata();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPage]);

  return (
    <LabourWrapper>
      <div className="heading-section">Equipment</div>
      <div className="filters">
        <div className="search-wrapper">
          <SearchField handleSearch={handleSearch} />
        </div>
        <ButtonComponent
          text="Add"
          onClick={() => handleDrawerOpenClose("add", "Add Equipment")}
        />
      </div>
      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
      {openDrawer?.status && (
        <DrawerComponent
          open={openDrawer?.status}
          handleClose={handleDrawerOpenClose}
          title={openDrawer?.title || "Add Equipment"}
        >
          <Addequipment
            type={openDrawer?.type || "add"}
            handleDrawerOpenClose={handleDrawerOpenClose}
            initialValues={openDrawer?.data}
            handleSuccess={handleSuccess}
          />
        </DrawerComponent>
      )}
    </LabourWrapper>
  );
}

const LabourWrapper = styled(OuterContainer)`
  .filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  .search-wrapper {
    flex: 1;
  }
`;
const Buttonstatus = styled.button`
  background: green;
  font-size: 14px;
  border: none;
  color: white;
  padding: 5px;
  border-radius: 5px;
`;
