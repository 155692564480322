import React, { useCallback, useEffect, useState } from "react";
import { OuterContainer } from "../style";
import styled from "styled-components";
import SearchField from "../../components/fields/searchField";
import TableComponent from "../../components/table";
import { errorMessage, formatPhoneNumber } from "../../utils/commonMethod";
import { getAlllist, getsearchdata } from "../../services/Api/collection";
import { useDispatch } from "react-redux";
import ModalComponent from "../../components/modal";
import { Button, Image } from "antd";
import { PictureOutlined } from '@ant-design/icons';
import CustomePagination from "../../components/table/pagination";
import { load } from "../../services/redux/Loader";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getProcoreUserUrl } from "../../config/environment";

export default function Allproject() {
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState("");
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [record, setRecord] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleSearch = (searchText) => setSearchText(searchText);
  const handleDrawerOpenClose = () => {
    setOpenModal((prev) => !prev);
  };
  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page);
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "project_name",
      key: "name",
      width: '10%',
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: '12%',
      render: (_, record) => {
        const addressParts = [record.address, record.city, record.zip];
        const isEmptyAddress = addressParts.every(part => !part || part === 'N/A');
        return (
          <div>
            {isEmptyAddress ? 'Empty Address' : addressParts.join(', ')}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: '10%',
      render: (text, record) => {
        const statusColors = {
          Completed: '#a0d468',
          InProgress: '#ffce54',
          Rejected: '#ed5565',
          Pending: '#ffcd00',
        };
        const color = statusColors[record.status] || 'grey';
        return (
          <div
            style={{
              backgroundColor: color,
              padding: '5px 10px',
              borderRadius: '5px',
              color: 'black',
              textAlign: 'center',
              fontWeight: 'bold',
              minWidth: '80px',
              textTransform: 'capitalize',
            }}
          >
            {record.status}
          </div>
        );
      },
    },
    {
      title: "Created By",
      key: "createdBy",
      width: '12%',
      render: (_, record) => {
        if (!record.usersData || record.usersData.length === 0) return 'N/A';
        const user = record.usersData[0];
        return (
          <div style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
            <div style={{ fontWeight: 'bold' }}>{user.name || 'N/A'}</div>
            <div>{user.email_address || 'N/A'}</div>
            <div>{formatPhoneNumber(user.mobile_phone) || 'N/A'}</div>
          </div>
        );
      },
    },
    {
      title: "Project Manager",
      key: "projectManager",
      width: '12%',
      render: (_, record) => {
        if (!record.projectsMember || record.projectsMember.length === 0) return 'None';
        return (
          <div style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
            {record.projectsMember.map((member, index) => (
              <div key={index} style={{ marginBottom: index !== record.projectsMember.length - 1 ? '8px' : 0 }}>
                <div style={{ fontWeight: 'bold' }}>{member.name || 'N/A'}</div>
                <div>{member.email_address || 'N/A'}</div>
                <div>{formatPhoneNumber(member.mobile_phone) || 'N/A'}</div>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Images',
      key: 'images',
      width: '5%',
      render: (_, record) => (
        record.photos && record.photos.length > 0 ? (
          <Button
            icon={<PictureOutlined />}
            onClick={() => {
              setSelectedImages(record.photos);
              setImageModalVisible(true);
            }}
          />
        ) : null
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: '8%',
      render: (_, data) => (
        <p>{moment(data.start_date).format('MMM D, YYYY')}</p>
      ),
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
    },
    {
      title: "Actions",
      key: "actions",
      width: '20%',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={() => {
              setOpenModal(true);
              setRecord(record);
            }}
          >
            View Details
          </Button>
            <a
            //PROD/DEV/LOCAL SWITCH - Using centralized configuration
            href={getProcoreUserUrl(record.user_id)}
            target="_blank"
            rel="noreferrer"
            >
            <Button>User Details</Button>
          </a>
        </div>
      ),
    },
  ];
  

  const limit = 10;
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const dispatch = useDispatch();
  const handleSearchData = async (filterPath) => {
    setLoading(true);
    const isAccept = 5;
    const res = await getsearchdata(currentPage, limit, searchText, isAccept);
    if (res?.status === 200) {
      setTotalCount(res?.data?.TotalCount);
      setData(res?.data?.finalRequestedData);
    } else errorMessage(res);
    setLoading(false);
  };

  const getProjectLists = async () => {
    setLoading(true);
    dispatch(load(true));
    let project_id;
    if (params.has("project_id")) {
      project_id = params.get("project_id");
    } else {
      project_id = "All";
    }

    const res = await getAlllist(currentPage, limit, project_id);
    if (res?.status === 200) {
      setData(res?.data?.Requests);
      setTotalCount(res?.data?.TotalCount);
      dispatch(load(false));
    } else {
      errorMessage(res);
      dispatch(load(false));
    }
    dispatch(load(false));
    setLoading(false);
  };

  useEffect(() => {
    if (searchText) handleSearchData();
    else {
      getProjectLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPage]);

  return (
    <Projectwrapper>
      <div className="filters">
        <div className="search-wrapper">
          <SearchField handleSearch={handleSearch} />
        </div>
      </div>

      {/* Image Modal */}
      <ModalComponent
        openModal={imageModalVisible}
        setOpenModal={() => setImageModalVisible(false)}
      >
        <Image.PreviewGroup>
          {selectedImages.map((imageUrl, index) => (
            <Image
              key={index}
              src={`https://de7nc1t6mbc89.cloudfront.net/${imageUrl}`}
              style={{ margin: '10px', maxWidth: '200px' }}
              alt={`Project Image ${index + 1}`}
            />
          ))}
        </Image.PreviewGroup>
      </ModalComponent>

      {/* Details Modal */}
      <ModalComponent
        openModal={openModal}
        setOpenModal={handleDrawerOpenClose}
      >
        <Detailsmodal>
          <h2>{record?.project_name ?? "Project Details"}</h2>
          <DetailItem>
            <span className="label">Address:</span>
            <span className="value">
              {(() => {
                const addressParts = [
                  record?.address ?? 'N/A',
                  record?.city ?? 'N/A',
                  record?.zip ?? 'N/A',
                ];
                const isEmptyAddress = addressParts.every(
                  (part) => !part || part === 'N/A'
                );
                return isEmptyAddress ? 'Empty Address' : addressParts.join(', ');
              })()}
            </span>
          </DetailItem>
          <DetailItem>
            <span className="label">Area:</span>
            <span className="value">{record?.area ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Status:</span>
            <span className="value">{record?.status ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Start Date:</span>
            <span className="value">
              {record?.start_date
                ? moment(record.start_date).format('MMM D, YYYY')
                : "N/A"}
            </span>
          </DetailItem>
          <DetailItem>
            <span className="label">Start Time:</span>
            <span className="value">{record?.start_time ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">End Date:</span>
            <span className="value">
              {record?.end_date
                ? moment(record.end_date).format('MMM D, YYYY')
                : "N/A"}
            </span>
          </DetailItem>
          <DetailItem>
            <span className="label">End Time:</span>
            <span className="value">{record?.end_time ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Comment:</span>
            <span className="value">{record?.comment ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">County:</span>
            <span className="value">{record?.county ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">State Code:</span>
            <span className="value">{record?.state_code ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Zip:</span>
            <span className="value">{record?.zip ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Equipment Required:</span>
            <span className="value">
              {record?.equipment?.length
                ? record?.equipment?.map((e, index) => (
                    <span key={index}>
                      {e?.equipment_name} ({e?.equipment_size})
                      {index < record.equipment.length - 1 ? ", " : ""}
                    </span>
                  ))
                : "N/A"}
            </span>
          </DetailItem>
          <DetailItem>
            <span className="label">Labour Required:</span>
            <span className="value">{record?.labor_size ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Labour Type:</span>
            <span className="value">
              {record?.labor_type === 0
                ? "General"
                : record?.labor_type === 1
                ? "Skilled"
                : "N/A"}
            </span>
          </DetailItem>
        </Detailsmodal>
      </ModalComponent>

      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
    </Projectwrapper>
  );
}

const Projectwrapper = styled(OuterContainer)`
  .search-wrapper {
    padding: 10px 0px;
  }
`;

const Detailsmodal = styled.div`
  max-width: 600px;
  margin: 0 auto;
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const DetailItem = styled.div`
  display: flex;
  padding: 5px 0;
  .label {
    flex: 1;
    font-weight: bold;
  }
  .value {
    flex: 2;
  }
`;
