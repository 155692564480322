import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import SearchField from "../../components/fields/searchField";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import { errorMessage, formatPhoneNumber } from "../../utils/commonMethod";
import ModalComponent from "../../components/modal";
import { Button, Image } from "antd";
import { PictureOutlined } from "@ant-design/icons";
import moment from "moment";
import { getProcoreUserUrl } from "../../config/environment";

import {
  getRejectedtaskapi,
  getsearchdata,
} from "../../services/Api/collection";

export default function Rejected() {
  const [loading, setLoading] = useState([]);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [record, setRecord] = useState();
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const limit = 10;

  const columns = [
    {
      title: "Name",
      dataIndex: "project_name",
      key: "project_name",
      width: "15%",
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, record) => {
        const addressParts = [record.address, record.city, record.zip];
        const isEmptyAddress = addressParts.every(
          (part) => !part || part === "N/A"
        );
        return (
          <div>
            {isEmptyAddress ? "Empty Address" : addressParts.join(", ")}
          </div>
        );
      },
      sorter: (a, b) => {
        const aAddress = [a.address, a.city, a.zip].join(", ");
        const bAddress = [b.address, b.city, b.zip].join(", ");
        return aAddress.localeCompare(bAddress);
      },
    },
    {
      title: "Images",
      key: "images",
      render: (_, record) =>
        record.photos && record.photos.length > 0 ? (
          <Button
            icon={<PictureOutlined />}
            onClick={() => {
              setSelectedImages(record.photos);
              setImageModalVisible(true);
            }}
          />
        ) : null,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (_, data) => (
        <p>{moment(data.start_date).format("MMM D, YYYY")}</p>
      ),
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
    },
    {
      title: "Labor",
      dataIndex: "labor_size",
      key: "labor_size",
      sorter: (a, b) => a.labor_size - b.labor_size,
    },
    {
      title: "Equipment",
      dataIndex: "equipment",
      key: "equipment",
      render: (_, record) => (
        <div>
          {record?.equipment.map((e, index) => (
            <span key={index}>
              {e?.equipment_name}
              {index < record.equipment.length - 1 ? ", " : ""}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Created By",
      key: "createdBy",
      width: "12%",
      render: (_, record) => {
        if (!record.usersData || record.usersData.length === 0) return 'N/A';
        const user = record.usersData[0];
        return (
          <div style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
            <div style={{ fontWeight: 'bold' }}>{user.name || 'N/A'}</div>
            <div>{user.email_address || 'N/A'}</div>
            <div>{formatPhoneNumber(user.mobile_phone) || 'N/A'}</div>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const statusColors = {
          Completed: "#a0d468",
          InProgress: "#ffce54",
          Rejected: "#ed5565",
          Pending: "#ffcd00",
        };
        const color = statusColors[record.status] || "grey";
        return (
          <div
            style={{
              backgroundColor: color,
              padding: "5px 10px",
              borderRadius: "5px",
              color: "black",
              textAlign: "center",
              fontWeight: "bold",
              minWidth: "80px",
              textTransform: "capitalize",
            }}
          >
            {record.status}
          </div>
        );
      },
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Details",
      key: "details",
      render: (_, record) => (
        <Button
          onClick={() => {
            setOpenModal(true);
            setRecord(record);
          }}
        >
          View Details
        </Button>
      ),
    },
    {
      title: "User Details",
      key: "user_details",
      render: (_, dataIndex) => (
        <a
          //PROD/DEV/LOCAL SWITCH - Using centralized configuration
          href={getProcoreUserUrl(dataIndex.user_id)}
          target="_blank"
          rel="noreferrer"
        >
          <Button>User Details</Button>
        </a>
      ),
    },
  ];

  const handleSearchData = async () => {
    setLoading(true);
    const isAccept = 3;
    const res = await getsearchdata(currentPage, limit, searchText, isAccept);
    if (res?.status === 200) {
      setTotalCount(res?.data?.TotalCount);
      setData(res?.data?.finalRequestedData);
    } else errorMessage(res);
    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    const req = await getRejectedtaskapi(currentPage, limit);
    if (req?.status === 200) {
      setLoading(false);
      setData(req?.data?.Requests);
      setTotalCount(req?.data?.TotalCount);
    } else {
      errorMessage(req);
      setLoading(false);
    }
  };

  const handleSearch = (searchText) => {
    setCurrentPage(1);
    setSearchText(searchText);
  };

  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    if (searchText) handleSearchData();
    else {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPage]);

  const handleDrawerOpenClose = () => {
    setOpenModal(false);
  };

  return (
    <IngoingWrapper>
      <div className="search-wrapper">
        <SearchField handleSearch={handleSearch} />
      </div>

      {/* Image Modal */}
      <ModalComponent
        openModal={imageModalVisible}
        setOpenModal={() => setImageModalVisible(false)}
      >
        <Image.PreviewGroup>
          {selectedImages.map((imageUrl, index) => (
            <Image
              key={index}
              src={`https://de7nc1t6mbc89.cloudfront.net/${imageUrl}`}
              style={{ margin: "10px", maxWidth: "200px" }}
              alt={`Project Image ${index + 1}`}
            />
          ))}
        </Image.PreviewGroup>
      </ModalComponent>

      {/* Details Modal */}
      <ModalComponent
        openModal={openModal}
        setOpenModal={handleDrawerOpenClose}
      >
        <DetailsModal>
          <h2>{record?.project_name ?? "Project Details"}</h2>
          <DetailsGrid>
            <div className="detail-item">
              <span className="label">Address:</span>
              <span className="value">
                {(() => {
                  const addressParts = [
                    record?.address ?? "N/A",
                    record?.city ?? "N/A",
                    record?.zip ?? "N/A",
                  ];
                  const isEmptyAddress = addressParts.every(
                    (part) => !part || part === "N/A"
                  );
                  return isEmptyAddress
                    ? "Empty Address"
                    : addressParts.join(", ");
                })()}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">Status:</span>
              <span className="value">{record?.status ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">Start Date:</span>
              <span className="value">
                {record?.start_date
                  ? moment(record.start_date).format("MMM D, YYYY")
                  : "N/A"}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">Start Time:</span>
              <span className="value">{record?.start_time ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">End Date:</span>
              <span className="value">
                {record?.updatedAt
                  ? moment(record.updatedAt).format("MMM D, YYYY")
                  : "N/A"}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">Comment:</span>
              <span className="value">{record?.comment ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">County:</span>
              <span className="value">{record?.county ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">State Code:</span>
              <span className="value">{record?.state_code ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">Zip:</span>
              <span className="value">{record?.zip ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">Equipment Required:</span>
              <span className="value">
                {record?.equipment?.length
                  ? record?.equipment?.map((e, index) => (
                      <span key={index}>
                        {e?.equipment_name} ({e?.equipment_size})
                        {index < record.equipment.length - 1 ? ", " : ""}
                      </span>
                    ))
                  : "N/A"}
              </span>
            </div>
            <div className="detail-item">
              <span className="label">Labour Required:</span>
              <span className="value">{record?.labor_size ?? "N/A"}</span>
            </div>
            <div className="detail-item">
              <span className="label">Labour Type:</span>
              <span className="value">
                {record?.labor_type === 0
                  ? "General"
                  : record?.labor_type === 1
                  ? "Skilled"
                  : "N/A"}
              </span>
            </div>
          </DetailsGrid>
        </DetailsModal>
      </ModalComponent>

      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
    </IngoingWrapper>
  );
}

const IngoingWrapper = styled(OuterContainer)`
  .search-wrapper {
    padding: 20px 0px;
  }
`;

const DetailsModal = styled.div`
  max-width: 800px;
  margin: 0 auto;
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  .detail-item {
    display: flex;
    flex-direction: column;
    .label {
      font-weight: bold;
      margin-bottom: 5px;
    }
    .value {
      margin-left: 10px;
    }
  }
`;
