import React from "react";
import styled from "styled-components";
import { Table } from "antd";

export default function ViewDetails({ vehicle }) {
  const projectmember = vehicle?.projectsMember;
  const EquipmentsData = vehicle?.EquipmentsData;
  
  // Use vehiclesData instead of projectVehicles for vehicle information
  const vehicledata = vehicle?.vehiclesData?.map(vehicle => ({
    id: vehicle?.id || Math.random().toString(),
    vehicleNumber: vehicle?.vehicleNeroId || vehicle?.vehiclesNumber || "N/A",
    vehicleName: vehicle?.vehicleName || "Unknown Vehicle"
  })) || [];

  const memberColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email Address",
      dataIndex: "email_address",
      key: "email_address",
    },
  ];

  const vehicleColumns = [
    {
      title: "Vehicle Name",
      dataIndex: "vehicleName",
      key: "vehicleName",
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicleNumber",
      key: "vehicleNumber",
    },
  ];

  const equipmentColumns = [
    {
      title: "Equipment",
      dataIndex: "equipment",
      key: "equipment",
    },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
    },
  ];

  return (
    <ProjectView>
      <div className="heading">Disaster Project</div>

      <div className="section-heading">Assigned Members:</div>
      <Table
        columns={memberColumns}
        dataSource={projectmember}
        pagination={false}
        rowKey="id"
      />

      <div className="section-heading">Assigned Vehicles:</div>
      <Table
        columns={vehicleColumns}
        dataSource={vehicledata}
        pagination={false}
        rowKey="id"
      />

      <div className="section-heading">Assigned Equipment:</div>
      <Table
        columns={equipmentColumns}
        dataSource={EquipmentsData}
        pagination={false}
        rowKey="id"
      />
    </ProjectView>
  );
}

const ProjectView = styled.div`
  .heading {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
  }
  .section-heading {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .ant-table {
    margin-bottom: 20px;
  }
`;
