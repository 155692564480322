import styled from "styled-components";
import { Input } from "antd";

export const ContentContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  padding: 0 10px;
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  
  .ant-table-wrapper {
    overflow: auto;
  }
  
  .ant-table-container {
    border-radius: 8px;
  }
  
  .ant-table-thead > tr > th {
    background-color: #f7f9fc;
    font-weight: 600;
  }
  
  .ant-table-tbody > tr:hover > td {
    background-color: #f0f7ff;
  }
  
  .ant-table-pagination {
    margin: 16px;
    padding: 8px 0;
  }
`;

export const TreeViewContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  
  .ant-tree {
    font-size: 15px;
  }
  
  .ant-tree-node-content-wrapper:hover {
    background-color: #f0f7ff;
  }
`;

export const ViewToggle = styled.div`
  margin-top: 36px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  
  h4 {
    margin-bottom: 0;
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -8px;
      width: 40px;
      height: 3px;
      background-color: #1890ff;
      border-radius: 2px;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #1890ff;
  padding: 6px 10px;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(24, 144, 255, 0.1);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    color: #d9d9d9;
    cursor: not-allowed;
    
    &:hover {
      background-color: transparent;
      transform: none;
    }
  }
`;

export const TreeNodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
  border-radius: 4px;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: rgba(24, 144, 255, 0.05);
  }
`;

export const TreeNodeActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const PageHeader = styled.div`
  margin-bottom: 24px;
  
  h2 {
    margin-bottom: 8px;
  }
  
  .ant-typography-secondary {
    font-size: 16px;
    line-height: 1.6;
  }
`;

export const EditableInput = styled(Input)`
  width: 100px;
  margin-right: 8px;
  border-radius: 4px;
  
  &:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

export const ValueDisplay = styled.span`
  background-color: ${props => props.$highlighted ? '#f0f7ff' : 'transparent'};
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-weight: ${props => props.$highlighted ? '500' : 'normal'};
`;

export const CodeLabel = styled.span`
  font-weight: 600;
  margin-right: 8px;
  color: #1890ff;
`; 