import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ActionWrapper, OuterContainer } from "../style";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import {
  getRequestsApi,
  updateRequestApi,
} from "../../services/Api/collection";
import { Button, Image, Tooltip, message } from "antd";
import {
  PictureOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import ModalComponent from "../../components/modal";
import ConfirmModal from "../../components/modal/confirmModal";
import { errorMessage, formatPhoneNumber } from "../../utils/commonMethod";
import moment from "moment";
import { getProcoreUserUrl } from "../../config/environment";

const limit = 10;
export default function Requests() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [record, setRecord] = useState();
  const [cancelModal, setCancelModal] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const onPageChange = (page) => setCurrentPage(page);

  const getNewRequestList = async () => {
    setLoading(true);
    let project_id;
    if (params.has("project_id")) {
      project_id = params.get("project_id");
    } else {
      project_id = "All";
    }
    const res = await getRequestsApi(currentPage, limit, project_id);
    if (res?.status === 200) {
      const { Requests = [], TotalCount } = res?.data || {};
      setData(Requests);
      setTotalCount(TotalCount);
    }
    setLoading(false);
  };

  const handleDrawerOpenClose = () => {
    setOpenModal((pre) => !pre);
  };

  useEffect(() => {
    getNewRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const openAcceptConfirmModal = (userdetail) => {
    setUserInfo(userdetail);
    closeOpenAcceptModal();
  };

  const openCancelConfirmModal = (userDetail) => {
    setUserInfo(userDetail);
    closeOpenCancelModal();
  };

  const closeOpenAcceptModal = () => setOpenAcceptModal((pre) => !pre);

  const closeOpenCancelModal = () => setCancelModal((pre) => !pre);

  const handleAcceptConfirm = async () => {
    const res = await updateRequestApi({
      isAccept: "2",
      projectRequestId: userInfo?._id,
      status: "InProgress",
    });
    if (res?.status === 200) {
      message.success(res?.message);
      closeOpenAcceptModal();
      currentPage === 1 ? getNewRequestList() : setCurrentPage(1);
    } else errorMessage(res);
  };

  const handleDeleteConfirm = async () => {
    const res = await updateRequestApi({
      isAccept: "3",
      projectRequestId: userInfo?._id,
      status: "Rejected",
    });
    if (res?.status === 200) {
      message.success(res?.message);
      closeOpenCancelModal();
      currentPage === 1 ? getNewRequestList() : setCurrentPage(1);
    } else errorMessage(res);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "project_name",
      key: "name", 
      width: '10%',
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: '12%',
      render: (_, record) => {
        const addressParts = [record.address, record.city, record.zip];
        const isEmptyAddress = addressParts.every(part => !part || part === 'N/A');
        return (
          <div>
            {isEmptyAddress ? 'Empty Address' : addressParts.join(', ')}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: '10%',
      render: (_, record) => {
        const statusColors = {
          Completed: "#a0d468",
          InProgress: "#ffce54",
          Rejected: "#ed5565",
          Pending: "#ffcd00",
        };
        const color = statusColors[record.status] || "grey";
        return (
          <div
            style={{
              backgroundColor: color,
              padding: "5px 10px",
              borderRadius: "5px",
              color: "black",
              textAlign: "center",
              fontWeight: "bold",
              minWidth: "80px",
              textTransform: "capitalize",
            }}
          >
            {record.status}
          </div>
        );
      },
    },
    {
      title: "Created By",
      key: "createdBy", 
      width: '12%',
      render: (_, record) => {
        if (!record.usersData || record.usersData.length === 0) return 'N/A';
        const user = record.usersData[0];
        return (
          <div style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
            <div style={{ fontWeight: 'bold' }}>{user.name || 'N/A'}</div>
            <div>{user.email_address || 'N/A'}</div>
            <div>{formatPhoneNumber(user.mobile_phone) || 'N/A'}</div>
          </div>
        );
      },
    },
    {
      title: "Project Manager",
      key: "projectManager",
      width: '12%', 
      render: (_, record) => {
        if (!record.projectsMember || record.projectsMember.length === 0) return 'None';
        return (
          <div style={{ whiteSpace: 'normal', lineHeight: '1.5' }}>
            {record.projectsMember.map((member, index) => (
              <div key={index} style={{ marginBottom: index !== record.projectsMember.length - 1 ? '8px' : 0 }}>
                <div style={{ fontWeight: 'bold' }}>{member.name || 'N/A'}</div>
                <div>{member.email_address || 'N/A'}</div>
                <div>{formatPhoneNumber(member.mobile_phone) || 'N/A'}</div>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Images",
      key: "images",
      width: '5%',
      render: (_, record) => (
        record.photos && record.photos.length > 0 ? (
          <Button
            icon={<PictureOutlined />}
            onClick={() => {
              setSelectedImages(record.photos);
              setImageModalVisible(true);
            }}
          />
        ) : null
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: '10%',
      render: (_, record) => (
        <div>{moment(record?.start_date).format("MMM D, YYYY")}</div>
      ),
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
    },
    {
      title: "Actions",
      key: "actions",
      width: '40%',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
          <Button
            onClick={() => {
              setOpenModal(true);
              setRecord(record);
            }}
          >
            View Details
          </Button>
            <a
            //PROD/DEV/LOCAL SWITCH - Using centralized configuration
            href={getProcoreUserUrl(record.user_id)}
            target="_blank"
            rel="noreferrer"
          >
            <Button>User Details</Button>
          </a>
          <ActionWrapper>
            <Tooltip title="Accept Request">
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => openAcceptConfirmModal(record)}
              >
                Accept
              </Button>
            </Tooltip>
            <Tooltip title="Cancel Request">
              <Button
                type="danger"
                icon={<CloseOutlined />}
                onClick={() => openCancelConfirmModal(record)}
              >
                Reject
              </Button>
            </Tooltip>
          </ActionWrapper>
        </div>
      ),
    },
   ];

  return (
    <RequestWrapper>
      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />

      {/* Image Modal */}
      <ModalComponent
        openModal={imageModalVisible}
        setOpenModal={() => setImageModalVisible(false)}
      >
        <Image.PreviewGroup>
          {selectedImages.map((imageUrl, index) => (
            <Image
              key={index}
              src={`https://de7nc1t6mbc89.cloudfront.net/${imageUrl}`}
              style={{ margin: "10px", maxWidth: "200px" }}
              alt={`Project Image ${index + 1}`}
            />
          ))}
        </Image.PreviewGroup>
      </ModalComponent>

      {/* Details Modal */}
      <ModalComponent
        openModal={openModal}
        setOpenModal={handleDrawerOpenClose}
      >
        <DetailsModal>
          <h2>{record?.project_name ?? "Project Details"}</h2>
          <DetailItem>
            <span className="label">Address:</span>
            <span className="value">
              {(() => {
                const addressParts = [
                  record?.address ?? "N/A",
                  record?.city ?? "N/A",
                  record?.zip ?? "N/A",
                ];
                const isEmptyAddress = addressParts.every(
                  (part) => !part || part === "N/A"
                );
                return isEmptyAddress
                  ? "Empty Address"
                  : addressParts.join(", ");
              })()}
            </span>
          </DetailItem>
          <DetailItem>
            <span className="label">Status:</span>
            <span className="value">{record?.status ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Start Date:</span>
            <span className="value">
              {record?.start_date
                ? moment(record.start_date).format("MMM D, YYYY")
                : "N/A"}
            </span>
          </DetailItem>
          <DetailItem>
            <span className="label">Start Time:</span>
            <span className="value">{record?.start_time ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">End Date:</span>
            <span className="value">
              {record?.end_date
                ? moment(record.end_date).format("MMM D, YYYY")
                : "N/A"}
            </span>
          </DetailItem>
          <DetailItem>
            <span className="label">End Time:</span>
            <span className="value">{record?.end_time ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Comment:</span>
            <span className="value">{record?.comment ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">County:</span>
            <span className="value">{record?.county ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">State Code:</span>
            <span className="value">{record?.state_code ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Zip:</span>
            <span className="value">{record?.zip ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Equipment Required:</span>
            <span className="value">
              {record?.equipment?.length
                ? record?.equipment?.map((e, index) => (
                    <span key={index}>
                      {e?.equipment_name} ({e?.equipment_size})
                      {index < record.equipment.length - 1 ? ", " : ""}
                    </span>
                  ))
                : "N/A"}
            </span>
          </DetailItem>
          <DetailItem>
            <span className="label">Labour Required:</span>
            <span className="value">{record?.labor_size ?? "N/A"}</span>
          </DetailItem>
          <DetailItem>
            <span className="label">Labour Type:</span>
            <span className="value">
              {record?.labor_type === 0
                ? "General"
                : record?.labor_type === 1
                ? "Skilled"
                : "N/A"}
            </span>
          </DetailItem>
        </DetailsModal>
      </ModalComponent>

      {openAcceptModal && (
        <ModalComponent
          openModal={openAcceptModal}
          setOpenModal={closeOpenAcceptModal}
        >
          <ConfirmModal
            handleConfirm={handleAcceptConfirm}
            subheading="You want to accept the request."
            handleCancel={closeOpenAcceptModal}
            confirmBtnText="Accept"
          />
        </ModalComponent>
      )}

      {cancelModal && (
        <ModalComponent
          openModal={cancelModal}
          setOpenModal={closeOpenCancelModal}
        >
          <ConfirmModal
            subheading="You want to delete the request."
            handleConfirm={handleDeleteConfirm}
            handleCancel={closeOpenCancelModal}
          />
        </ModalComponent>
      )}
    </RequestWrapper>
  );
}

const RequestWrapper = styled(OuterContainer)`
  .back-button {
    display: flex;
    color: grey;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
  }
`;

const DetailsModal = styled.div`
  max-width: 600px;
  margin: 0 auto;
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const DetailItem = styled.div`
  display: flex;
  padding: 5px 0;
  .label {
    flex: 1;
    font-weight: bold;
  }
  .value {
    flex: 2;
  }
`;
